<script>
export default {
  props: {
    label: String,
    disableGrid: Boolean
  }
}
</script>

<template>
  <div class="form-control">
    <label class="form-control__label">{{ this.label }}</label>
    <div class="form-control__input" :class="{ 'disable-grid': this.disableGrid }">
      <slot />
    </div>
  </div>
</template>

<style lang="scss">
// @use '@/assets/css/lh-ds/core/icon';
// @use '@/assets/css/lh-ds/core/color';
// @use '@/assets/css/lh-ds/core/font';
@use '@/assets/css/lh-ds/core/functions' as *;

.form-control {
  &__label {
    display: block;
    margin-bottom: rem(8px);
    font-weight: bold;
    font-size: rem(16px);
  }

  &__input {
    &:not(.disable-grid) {
      display: grid;
      grid-template-columns: 1fr;

      & > * {
        min-width: 0;
      }
    }
  }
}
</style>